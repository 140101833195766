<template>
    <button @click="goBack()" id="back-button" title="Go Back">
        <img src="@/assets/img/ui/arrow-back.svg">
    </button>
</template>
<script>

export default {
    methods: {
        goBack() {
            app.api.PageManager.navigateToParentScene();
        }
    }
}
</script>
<style lang="scss" scoped>
#back-button {
    width: 50px;
    height: 50px;
    background-color: var(--dark-grey);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover { background-color: var(--black); }
}
</style>