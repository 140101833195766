<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenVideo" v-if="ready" :class="{'fullscreenVideo--idle': properties.idleMode}">

      <div class="fullscreenVideo__topbar" v-if="!properties.idleMode">
        <h2 v-html="getComponentTitle"></h2>
        <button class="fullscreenVideo__topbar_close" @click="closeComponent"><img src="@/assets/img/ui/close.svg"></button>
      </div>
    
      <video ref="video"
        v-if="properties.video.src"
        :autoplay="properties.autoplay || properties.idleMode"
        :controls="properties.controls && !properties.idleMode"
        :muted="properties.mute"
        :loop="properties.loop || properties.idleMode"
        :poster="posterUrl"
        type="video/mp4"
        playsinline
        webkit-playsinline
        crossorigin>
        <source :src="videoUrl">
      </video>

    </article>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "children",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.properties.video.src)
    },
    posterUrl() {
      return this.properties.poster ? app.api.Utils.getMediaPath(this.properties.poster.src) : null;
    },
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    }
  }
};
</script>

<style lang="scss">
.fullscreenVideo {
  position: absolute;
  inset: 150px 400px 150px auto;
  
  &>video {
    aspect-ratio: 16 / 9;
    background-color: var(--black );
    height: calc(100% - 20px); // fusk
    outline: 10px solid black; // fusk
    margin: 10px; // fusk => så videoen holder 16:9 format, men stadig har 10px padding
    right: 0;
    position: inherit;
  }

  &__topbar {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 10;
    top: 0;
    right: -400px;

    h2{
      font-size: 25px;
      font-weight: 500;
      text-align: left;
      color: white;
      background-color: rgb(0, 0, 0, 0.85);
      width: 400px;
      min-height: 75px;
      padding: 22px 30px;
    }

    &_close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        background-color: var(--red);
      }
  }

  &--idle {
    inset: 0;
    position: relative;

    &> video {
      aspect-ratio: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
      outline: 0;
      margin: 0;
    }
  }
}
</style>