<template>
    <transition name="HotspotEntrance">
        <router-link
            class="Hotspot infoBubble"
            v-show="ready"
            :to="hotspot.route"
            :style="[getPosition, getContinuousHotspotAnimation]"
            @click="hotspotPressed">
                <img src="@/assets/img/ui/close.svg">
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false,
            hotspotActive: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot);
        },
        closeComponent() {
            this.$emit("closeComponent", this.hotspot.slug);
        },
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getAnimationDelay() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            return {
                '--animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        },
    
        getContinuousHotspotAnimation() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            return {
                'animation-name': "hotspotLoopPulse",
                'animation-iteration-count': 'infinite',
                'animation-duration': '15s',
                'animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        },
    }
}
</script>


<style lang="scss">

.Hotspot.infoBubble {
    background-color: var(--black);
    width: 61px;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;

    img { transform: rotate(45deg); }

    &:hover { background-color: var(--red); }

    &::before, &::after { content: none; }

}

/* default*/
@keyframes hotspotLoopPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
        transform: scale(1.3);
    }

    7% {
        box-shadow: 0 0 0 120px rgba(0, 0, 0, 0);
        transform: scale(1.05);
    }

    10% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }
}
</style>