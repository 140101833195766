export default function Tracking(app) {

    let prevPageTitle = "";

    app.api.Tracking = Object.freeze({
        navigation,
        event
    })

    // Navigation with hooks in the $Router
    function navigation(event, property, pageName, type) {

        // get sections and content from route
        let [sections, currentContent] = property.split(":");
        let siteSections = sections.split("/").filter(Boolean);

        // merge content into sections list again
        if (currentContent)
            siteSections.push(currentContent);

        // set and extend contentpage type
        let pageType = (currentContent != undefined) ? "content" : "scene";
        if (type) {
            pageType += " (" + type + ")"
        }

        // build tracking object (for ADOBE LAUNCH)
        let trackingObject = {
            event: event,
            pageInfo: {
                "pageName": pageName, //name of the page - title etc.
                "prevPageName": prevPageTitle, //name of the previous page, leave empty if none
                "pageType": pageType,
                "siteSection1": siteSections[0] || "", //example: https://www.satair.com/market/product/22373/ --> 1 level = market
                "siteSection2": siteSections[1] || "", //example: https://www.satair.com/market/product/22373/ --> 2 level = product
                "siteSection3": siteSections[2] || "", //example: https://www.satair.com/market/product/22373/ --> 3 level = 22373
                "siteSection4": siteSections[3] || "",
                "siteSection5": siteSections[4] || "",
            },

        }

        prevPageTitle = pageName;
        //console.log(trackingObject);
        CreateLog(trackingObject)
    }

    // Explicitly called events with js (buttonclicks etc.)
    function event(action, title, type, value) {
        let trackingObject = {
            event: "interaction",
            interaction: {
                "action": action, //"Click" // what kind of interaction happened
                "label": title, //what it says on the link / button being clicked
                "component": type || "", // "Link", // type of component.
                "value": value || "" // when applicable - for links let's put the destination URL here
            }
        }

        //console.log(trackingObject);
        CreateLog(trackingObject)
    }

    function CreateLog(data) {
        adobeDataLayer.push(data);
        //console.log(`Tracking => \nEvent: ${data.event}\nPageInfo: ${JSON.stringify(data.pageInfo)}\nPayload: ${data.Payload}`);
    }
}