import { createRouter, createWebHashHistory } from 'vue-router'
import Page from '../components/core/page/Page.vue'

const routes = [
  { path: "/:id*", component: Page}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Send events to Tracking API
router.afterEach((to, from) => {
 
  let pageName = to.matched[0]?.instances?.default?.getPageData?.name || "Home";
  let openComp = to.matched[0]?.instances?.default?.contentComponents?.[0] || null;
  let pageTitle = (openComp) ? openComp.title : pageName;
  let type = (openComp) ? openComp.type : null;

  //console.log( openComp);
  
  
  app.api.Tracking.navigation("pageView", to.fullPath, pageTitle, type);
})

export default router
