<template>
    <transition name="toggleContentComponent">
        <article class="bubble" v-if="ready" :style="getPopupPosition">
            <h3>{{ getTitle }}</h3>
            <p v-html="properties.text"></p>
            <a :class="{'disabled':isOffline}" :href="properties.link" target="_blank">{{ getLinkText }}<span v-if="isOffline"> (offline)</span></a>
            <button class="bubble__close" @click="closeComponent()">
                <img src="@/assets/img/ui/close.svg">
            </button>
        </article>
    </transition>
</template>


<script>

export default {

    props: [
        "properties",
        "pageData",
        "activeHotspots",
        "children",
        "slug"
    ],
    data() {
        return {
            ready: false,
            positionData: {},
        }
    },
    mounted() {
        setTimeout(() => {
            this.positionData = this.activeHotspots.find(hotspot => hotspot.slug === this.slug).data.positionData;
            this.ready = true;
        }, 100);
    },
    methods: {
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        },
        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        }
    },
    computed: {
        getPopupPosition() {
            let offsetY = -0;
            let offsetX = -247;
            let responsiveOffsets = this.$store.getters.getOffsetValue;

            return {
                left: (this.positionData.xPosition + offsetX - parseInt(responsiveOffsets.left)) + 'px',
                top: (this.positionData.yPosition + offsetY) + 'px'
            }
        },
        getTitle() {
            return app.api.PageManager.getCustomComponentFromType("globalSettings").data.title || 'Did you know?';
        },
        getLinkText() {
            return app.api.PageManager.getCustomComponentFromType("globalSettings").data.linkText || 'Read more';
        },
        isOffline(){
            return (window.location.hostname === "");
        }
    }
       
};

</script>
<style lang="scss">

.bubble {
    font-size: 20px;
    color: var(--white);
    background-color: var(--black);
    width: 330px;
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    padding: 40px 40px 0;

    h3 {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 0.6em;
    }
    p {
        margin-bottom: 0;
        &:nth-last-child(2) { margin-bottom: 40px; } // når ikke noget link
    }
    a { // make link bigger => easier to hit
        font-weight: bold;
        width: 100%;
        padding: 1em 0 30px;

        &.disabled {
            pointer-events: none!important;
            opacity: 0.4!important;

            span {
                font-weight: normal;
                font-size: 0.8em;
            }
        }
    }

    .bubble__close {
        position: absolute;
        top: 0px;
        right: 22px;
        background-color: var(--red);
        width: 61px;
        height: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 60px;
    }
}
</style>