<template>
    <!-- offline disabled link -->
    <a v-if="isOffline" :href="content.link" target="_blank" @click="trackNavigation(content.link)" class="disabled">
        {{content.text}} <span>(sorry, we're offline)</span>
    </a>
    <!-- online link -->
    <a v-else :href="content.link" target="_blank" @click="trackNavigation(content.link)">
        {{content.text}}
    </a>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        trackNavigation(link) {
            app.api.Tracking.event("Click", "ExternalLink", "Article", link);
        }
    },
    computed: {
        isOffline(){
            return (window.location.hostname === "");
        }
    }

}
</script>
<style lang="scss" scoped>
    a {
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        font-size: 16px;
        color: var(--blue);
        font-weight: bold;
        margin-block: 42px;

        &.disabled {
            opacity: .4;
            pointer-events: none;

            &>span{
                font-weight: normal;
                font-size: 0.8em;
            }
        }

        &::before {
            content: url(@/assets/img/ui/link.svg);
            width: 32px;
            height: 32px;
        }
        
        .contentBlockSmall & { margin-block: 20px; }
        &:last-child { margin-bottom: 0; }

    }
</style>