<template>
  <div class="language" :class="{open: languageOpen}" v-click-outside>
    <button class="language__button" @click="toggleLangDropdown()">
      <img src="@/assets/img/ui/flag-zh.svg" v-if="currentLang === 'zh'">
      <img src="@/assets/img/ui/flag-en.svg" v-else>
    </button>

    <div class="language__list" v-show="languageOpen">
      <button @click="setLang('en'), languageOpen = false" v-if="currentLang === 'zh'">
        <img src="@/assets/img/ui/flag-en.svg" >
      </button>
      <button @click="setLang('zh'), languageOpen = false" v-else>
        <img src="@/assets/img/ui/flag-zh.svg" >
      </button>
    </div>
  </div>
</template>

<script>

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = e => {
            if (!(el == e.target || el.contains(e.target))) {
                binding.instance.languageOpen = false;
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    }
}

export default {
  data() {
    return {
      languageOpen: false,
      currentLang: null
    }
  },
  mounted() {
    this.currentLang = app.api.store.get("lang");
  },
  directives: {
    clickOutside
  },
  methods: {
    toggleLangDropdown(){
      this.languageOpen = !this.languageOpen;
      if (this.languageOpen)
        app.api.Tracking.event("click", "Language", "Language Selector Opened");
    },
    setLang(lang) {
      let langChangeTo = `Language Changed to ${lang === 'zh' ? 'Chinese' : 'English'}` ;
      app.api.store.set("lang", lang);
      app.api.Tracking.event("click", "Language", langChangeTo);
      location.reload();
    }
  }
}
</script>

<style lang="scss">
.language {
  position: relative;

  img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }

  &__button {
    width: 50px;
    height: 50px;
    background-color: #333;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover { background-color: var(--black); }
  }

  &__list {
    position: absolute;
    top: 54px;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 4px;

    button {
      width: 50px;
      height: 50px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(60, 60, 60, 0.85);

      &:hover { background-color: rgb(0, 0, 0, 0.85); }
    }
  }

  // when open
  &.open {
    .contact__button {
      background-color: var(--black);
      &:hover { background-color: var(--light-grey);}
    }
  }
}
</style>