<template>
  <div class="videoLocal" v-if="content.video.src">
    <video ref="video"
      controls
      :poster="posterUrl"
      type="video/mp4"
      playsinline
      webkit-playsinline
      crossorigin>
      <source :src="videoUrl">
    </video>
  </div>
</template>
<script>
export default {
  props: ["content"],
  data() {
    return {}
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.content.video.src)
    },
    posterUrl() {
      return this.content.poster ? app.api.Utils.getMediaPath(this.content.poster.src) : null;
    },
  }
}
</script>
<style lang="scss" scoped>

// if part of large content block
.contentBlockLarge .videoLocal {
  margin-block: 42px;
  &:last-child { margin-bottom: 0; }
}

// if part of small content block
.contentBlockSmall .videoLocal {
  margin-block: 42px;
  &:last-child { margin-bottom: 0; }
}

video {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}
</style>