<template>
  <transition name="toggleContentComponent">
    <article class="contentBlockSmall" :class="{'flipped': properties.flipped, 'runIntro': runIntro}" v-if="ready">
      
      <button
        class="contentBlockSmall__close"
        @click="closeComponent"
        title="Close"
      ><img src="@/assets/img/ui/close.svg"></button>

      <ul class="contentBlockSmall__nav" role="tablist">
        <li role="presentation" v-for="tab, i in children">
          <button
          @click="activeTab = i"
          class="contentBlockSmall__nav_button"
          :class="[{active: activeTab === i}]"
          >{{ tab.data.title }}</button>
        </li>
      </ul>

      <template v-for="tab, i in children">
        <div class="contentBlockSmall__content" v-show="activeTab === i">
          <img v-if="tab.data.coverImage" :src="getSource(tab.data.coverImage.src)" class="contentBlockSmall__content_cover">
          <div class="contentBlockSmall__content_wrapper">
            <h1>{{ tab.data.title }}</h1>
            <component :is="getContentComponent(content)" v-for="(content, i) in tab.data.content" :key="content.nsAlias + '_' + i" :content="content"></component>
          </div>
        </div>
      </template>
    </article>
  </transition>
</template>

<script>
import { markRaw } from 'vue';

export default {
  props: [
    "properties",
    "pageData",
    "children",
    "slug"
  ],
  data() {
    return {
      ready: false,
      contentComponents: {},
      activeTab: 0,
      runIntro: true,
    }
  },
  created() {
    this.importArticleComponents();
  },
  mounted() {
    this.ready = true
    setTimeout(() => this.runIntro = false, 900);
  },
  methods: {
    importArticleComponents() {
      // Use require.context to dynamically import components from the ./hotspot-components subfolder
      const componentContext = require.context('./content-components/', true, /\.vue$/);
      
      // Iterate over each component path and extract the component name
      componentContext.keys().forEach((componentPath) => {
        const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

        // Import the component and mark it as non-reactive using markRaw
        this.contentComponents[componentName] = markRaw(componentContext(componentPath).default);
      });
    },

    getContentComponent(content) {
      // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
      // If the component is not found, use the 'defaultHotspot' component
      const componentName = content.nsAlias;
      const component = this.contentComponents[componentName];

      if (!component) {
        console.log('%c No component found for type: ' + componentName, 'color: red;');
      }

      return component;
    },

    closeComponent() {
      this.$emit("closeComponent", this.slug)
    },
    
    getSource(src) {
      return app.api.Utils.getMediaPath(src);
    },
  }
};
</script>

<style lang="scss">
  .contentBlockSmall {
    --button-size: 75px;
    --tab-width: 400px;
    --content-width: 600px;

    // animation properties
    --anim-duration: 0.4s;
    --anim-timing: ease-out;
    --anim-delay: 0.5s;

    position: absolute;
    top: 150px;
    right: 500px;
    bottom: 150px;
    width: var(--content-width);
    
    // close button
    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--button-size);
        height: var(--button-size);
        background-color: var(--red);
        position: absolute;
        z-index: 2;
        bottom: 150px;
        right: 0;
      }

    // content navigation
    &__nav {
      display: flex;
      flex-direction: column-reverse;
      justify-content: end;
      gap: 4px;
      position: absolute;
      bottom: 150px;
      left: var(--content-width);

      li { position: relative; }
      
      &_button {
        font-size: 25px;
        text-align: left;
        color: white;
        background-color: rgb(60, 60, 60, 0.85);
        width: var(--tab-width);
        min-height: var(--button-size);
        padding: 21px 30px;

        &.active {
          font-weight: 500;
          background-color: rgb(0, 0, 0, 0.85);
        }
        &:hover { background-color: rgb(0, 0, 0, 0.85); }
      }
    }

    // content block
    &__content {
      text-align: left;
      background-color: white;
      width: var(--content-width);
      height: 100%;
      position: absolute;
      right: 0;

      &_cover {
        display: block;
        width: 100%;
        height: 250px;
        object-fit: cover;
        width: var(--content-width);
      }

      &_wrapper {
        height: 100%;
        overflow-y: auto;
        padding: 50px 120px 50px 60px;
        width: var(--content-width);
      }

      &_cover ~ &_wrapper { height: calc(100% - 250px); }

      // typography
      h1 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }

    // if flipped layout
    &.flipped {

      .contentBlockSmall__close {
        left: 0;
        right: unset;
      }
      .contentBlockSmall__nav {
        left: unset;
        right: var(--content-width);
        
        &_button { text-align: right; }
      }
      .contentBlockSmall__content {
        left: 0;
        right: unset;
        &_wrapper { padding: 50px 60px 50px 120px; }
      }
    }

    // intro animation
    &.runIntro {
      .contentBlockSmall__nav, .contentBlockSmall__content {
        overflow: hidden;
        animation-duration: var(--anim-duration);
        animation-fill-mode: both;
        animation-timing-function: var(--anim-timing);
        animation-delay: var(--anim-delay);
      }
      .contentBlockSmall__nav { animation-name: navEnter; }
      .contentBlockSmall__content { animation-name: blockEnter; }
    }
  }

  @keyframes blockEnter {
    0% { width: 0; }
    100% { width: var(--content-width); }
  }

  @keyframes navEnter {
    0% { width: 0; }
    100% { width: var(--tab-width); }
  }
</style>