<template>
    <div class="bodyText" v-html="content.text" :class="{'bodyText--highlighted': content.highlight}"></div>
</template>

<script>
export default {
    props: ["content"]
}
</script>

<style lang="scss">
.bodyText {
    max-width: 780px;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;

    &:last-child { margin-bottom: 0; }

    p {
        margin-bottom: 16px;
        &:last-child { margin-bottom: 0; }
    }

    ul {
        list-style: disc;
        padding-left: 26px;
        margin-bottom: 16px;

        &:last-child { margin-bottom: 0; }

        li {
            margin-bottom: 8px;

            &:last-child { margin-bottom: 0; }
        }
    }

    a { text-decoration: underline; }

    // fusk indtil tinyMCE wrapper ting i p-tags igen
    div {
        margin-bottom: 16px;
        &:last-child { margin-bottom: 0; }
    }
}
</style>