<template>
  <transition name="toggleContentComponent">
    <article class="images" v-if="ready" @pointerdown="handlePointerDown" @pointermove="handlePointerMove">

      <div class="images__topbar">
        <h2 v-html="getComponentTitle"></h2>
        <button class="images__topbar_close" @click="closeComponent"><img src="@/assets/img/ui/close.svg"></button>
      </div>

      <div class="images__wrapper">

        <div class="images__slide" v-for="img, i in properties.imageList" :class="{ 'active': i == currentIndex }">
          <img :src="getSrc(img.src)">
        </div>

        <div class="images__navigation" v-if="properties.imageList.length > 1">
            <a class="prev" @click="prev()"><img src="@/assets/img/ui/arrow-back.svg"></a>
            <a class="next" @click="next()"><img src="@/assets/img/ui/arrow-next.svg"></a>
        </div>

      </div>

    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "children",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
      currentIndex: 0,
      navigationOn: false,
      navTimer: null,
      startX: 0,
      swipeThreshold: 50,
    }
  },
  mounted() {
    this.ready = true
    console.log(this.properties);
  },
  methods: {
    getSrc(src) {
      return app.api.Utils.getMediaPath(src);
    },

    prev() {
        this.currentIndex = (this.properties.imageList.length + (this.currentIndex - 1)) % this.properties.imageList.length;
    },

    next() {
      this.currentIndex = (this.currentIndex + 1) % this.properties.imageList.length;
    },

    handlePointerDown(event) {
      // Check if the event is a touch or a mouse event
      if (event.pointerType === 'touch' || event.pointerType === 'pen') {
        this.startX = event.clientX;
      }
      
    },

    handlePointerMove(event) {
      if (!this.startX) return;

      const currentX = event.clientX;
      const deltaX = currentX - this.startX;

      console.log(deltaX)

      if (deltaX > 0) {
        // Swiped left-to-right (previous)
        if (this.currentIndex > 0)
        this.prev();
      } else if (deltaX < 0) {
        // Swiped right-to-left (next)
        if (this.currentIndex < this.properties.imageList.length-1)
        this.next();
      }

      this.startX = 0; // Reset startX for the next pointerdown event
    },

    closeComponent() {
      this.$emit("closeComponent", this.slug)
    },
  },
  computed: {
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    }
  }
};
</script>

<style lang="scss">
.images {
  position: absolute;
  inset: 150px 400px 150px auto;

  /* topbar with styling */
  &__topbar {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 10;
    top: 0;
    right: -400px;
    
    h2{
      font-size: 25px;
      font-weight: 500;
      text-align: left;
      color: white;
      background-color: rgb(0, 0, 0, 0.85);
      width: 400px;
      min-height: 75px;
      padding: 22px 30px;
    }
    
    &_close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 75px;
      background-color: var(--red);
    }
  }

  /* content wrapper */
  &__wrapper {
    position: relative;
    background-color: var(--black);
    height: 100%;
    width: 1371px;
  }

  /* single image */
  &__slide {
    position: absolute;
    inset: 0;
    height: calc(100% - 20px);
    opacity: 0;
    transition: all .4s cubic-bezier(1,0,1,0); // delay fade until active is almost loaded
    margin: 10px;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
      z-index: 1;
      transition: all .4s var(--tk-easeinout);
    }

    img {
      aspect-ratio: 16 / 9;
      height: 100%;
      object-fit: cover;
    }
  }

  /* arrow navigation */
  &__navigation {
    position: absolute;
    z-index: 4;
    top: calc(50% - 10px);
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px;
    pointer-events: none;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      pointer-events: all;
      cursor: pointer;
      position: relative;
      transition: all 0.5s;
      background-color: var(--dark-grey);

      img {
        width: 20px;
        width: 20px;
        transition: transform 0.3s;
      }

      &:active {
        transition: none;
        background-color: rgb(60, 60, 60, 0.85);

        img {
          transform: scale(0.9);
          transition: none;
        }
      }
    }
  }
}
</style>