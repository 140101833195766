<template>
    <button id="home-button" @click="goHome">
        <img src="@/assets/img/ui/home.svg">
        <span v-text="getHomeText"></span>
    </button>
</template>
<script>

export default {
    methods: {
        goHome() {
            let route = app.api.PageManager.getRouteFromID(this.homePage);
            if (route) {
                app.api.PageManager.navigateToRoute(route)
            } else {
                app.api.PageManager.navigateToHomePage();
            }
        }
    },
    computed: {
        homePage() {
            return app.api.PageManager.getCustomComponentFromType("globalSettings").data.mainPage;
        },
        getHomeText() {
            return app.api.store.get("lang") === 'zh' ? '首页' : 'Home';
        }
    }
}
</script>

<style lang="scss" scoped>
#home-button {
    height: 50px;
    font-size: 15px;
    font-weight: 300;
    color: var(--white);
    background-color: var(--dark-grey);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0 20px;

    &:hover { background-color: var(--black); }
}
</style>