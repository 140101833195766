import { createStore } from 'vuex'

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false,

            scale: 1,
            backgroundWidth: 0,
            appResolution:{},
            MaximizedSize:{},
            Offset:{},
        }
    },
    mutations: {
        ready(state) {
            state.ready = true;
        },
        setScaleValue(state, value) {
            state.scale = value;
        },
        setAppResolutionValue(state, value) {
            state.appResolution = value;
        },
        setMaximizedSizeValue(state, value) {
            state.MaximizedSize = value;
        },
        setOffsetValue(state, value) {
            state.Offset = value;
        },
        setBackgroundWidth(state, value) {
            state.backgroundWidth = value;
        },
    },
    getters: {
        ready(state) {
            return state.ready
        },
        getScaleValue(state) {
            return state.scale;
        },
        getAppResolutionValue(state) {
            return state.appResolution;
        },
        getMaximizedSizeValue(state) {
            return state.MaximizedSize;
        },
        getOffsetValue(state) {
            return state.Offset;
        },
        getBackgroundWidth(state) {
            return state.backgroundWidth;
        },
    },
    actions: {
        ready(context) {
            context.commit('ready')
        },
    }
})

export default store;
