<template>
    <div class="contact" :class="{open: linksOpen}" v-click-outside>
        <button class="contact__button" title="Contact Us" @click="toggleLinks()">
            <img src="@/assets/img/ui/mail.svg">
        </button>

        <div v-if="getLinks" class="contact__links" v-show="linksOpen">
            <button v-for="link in getLinks" @click="openContent(link.component), linksOpen = false">{{ link.text }}</button>
        </div>
    </div>
</template>
<script>

const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = e => {
            if (!(el == e.target || el.contains(e.target))) {
                binding.instance.linksOpen = false;
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    }
}

export default {
    data() {
        return {
            linksOpen: false,
        }
    },
    directives: {
        clickOutside
    },
    methods: {
        toggleLinks(){
            this.linksOpen = !this.linksOpen;
            if (this.linksOpen)
            app.api.Tracking.event("click", "Contact", "Drop Down Opened");
         
        },
        openContent(id) {
            app.api.PageManager.openContentComponentFromID(id);
        },
    },
    computed: {
        getLinks() {
            return app.api.PageManager.getCustomComponentFromType("globalSettings").data.contactLinks;
        }
    }
}
</script>
<style lang="scss" scoped>
.contact {
    position: relative;

    &__button {
        width: 50px;
        height: 50px;
        background-color: var(--light-grey);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover { background-color: var(--black); }
    }

    &__links {
        position: absolute;
        top: 54px;
        left: 0;

        display: flex;
        flex-direction: column;
        gap: 4px;

        button {
            position: relative;
            font-size: 25px;
            width: max-content;
            color: var(--white);
            background-color: rgb(60, 60, 60, 0.85);
            padding: 12px 20px;

            &:hover { background-color: rgb(0, 0, 0, 0.85); }

            &:first-child {
                margin-bottom: 6px;
                &::after {
                    content: '';
                    pointer-events: none;
                    display: block;
                    height: 2px;
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    background-color: var(--black);
                }
            }
        }
    }

    // when open
    &.open {
        .contact__button {
            background-color: var(--black);
            &:hover { background-color: var(--light-grey);}
        }
    }
}
</style>