<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenIframePlayer fullscreenVideo" v-if="ready">

      <div class="fullscreenVideo__topbar">
        <h2 v-html="getComponentTitle"></h2>
        <button class="fullscreenVideo__topbar_close" @click="closeComponent"><img src="@/assets/img/ui/close.svg"></button>
      </div>

      <iframe ref="youtube"
        if="properties.youtubeUrl !== ''"
        :src="youtubeUrl"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe>

    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "children",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
      youtubeID: ''
    }
  },
  created() {
    if(this.properties.youtubeUrl !== '') 
      this.youtubeID = this.getYoutubeID(this.properties.youtubeUrl);
  },
  mounted() {
    this.ready = true
  },
  methods: {
    getYoutubeID(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    youtubeUrl () {
      return `https://www.youtube.com/embed/${this.youtubeID}`
    },
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    }
  }
};
</script>

<style lang="scss">
.fullscreenVideo {
  position: absolute;
  inset: 150px 400px 150px auto;
  
  &>iframe {
    height: calc(100% - 20px); // fusk
    aspect-ratio: 16 / 9;
    background-color: var(--black );
    outline: 10px solid black; // fusk
    margin: 10px; // fusk => så videoen holder 16:9 format, men stadig har 10px padding
  }

  &__topbar {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 10;
    top: 0;
    right: -400px;

    h2{
      font-size: 25px;
      font-weight: 500;
      text-align: left;
      color: white;
      background-color: rgb(0, 0, 0, 0.85);
      width: 400px;
      min-height: 75px;
      padding: 22px 30px;
    }

    &_close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 75px;
        background-color: var(--red);
      }
  }
}
</style>