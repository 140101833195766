<template>
    <h2 class="subtitle" v-html="content.text"></h2>
</template>

<script>
export default {
    props: ["content"]
}
</script>

<style lang="scss">
// if part of large content block
.contentBlockLarge .subtitle {
    font-size: 28px;
    font-weight: bold;
    max-width: 880px;
    margin-bottom: 42px;
}

// if part of small content block
.contentBlockSmall .subtitle {
    font-size: 16px;
    line-height: 1.6;
    font-weight: bold;
    margin-bottom: 20px;
}
</style>