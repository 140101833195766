<template>
    <img :src="computedSrc" class="articleImage">
</template>
<script>
export default {
    props: ["content"],
    computed: {
        computedSrc() {
            if(this.content.image)
                return app.api.Utils.getMediaPath(this.content.image.src)
        }
    }
}
</script>
<style lang="scss">

// if part of large content block
.contentBlockLarge .articleImage {
    width: 100%;
    display: block;
    margin-block: 42px;
}

// if part of small content block
.contentBlockSmall .articleImage {
    width: 100%;
    display: block;
    margin-block: 20px;
}

</style>