<template>
  <article id="AppLoader">
      <span>Loading ...</span>
  </article>
</template>

<style lang="scss">
#AppLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark-grey);
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::before {
        content:'';
        border: 3px solid var(--white);
        border-top: 3px solid var(--dark-grey);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s ease-in-out infinite;

        margin-bottom: 40px;
    }

    span {
        color: var(--white);
        font-size: 28px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>