<template>
    <transition name="HotspotEntrance">
        <router-link
            class="Hotspot"
            v-show="ready"
            :to="hotspot.route"
            :class="[getHotspotMutatorClasses, getHotspotTypeClass, getIconPosition]"
            :style="[getPosition, getAnimationDelay]"
            @click="hotspotPressed"
            v-html="getHotspotTitle"> 
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();

        // console.log(this.hotspot.componentData)
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--hidden': this.hotspot.data.hidden,
                'Hotspot--offline': this.hotspot.componentData && this.hotspot.componentData.type === "externalLink" && this.isOffline,
            }
        },
        getAnimationDelay() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            return {
                '--animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        },
        getIconPosition() {
            if (this.hotspot.componentData === null) { // if scene hotspot
                return 'scene';
            } else { // else if component hotspot
                switch (this.hotspot.data.iconPosition) {
                    case 'Top Right' :
                        return 'top-right';
                    case 'Bottom Left' :
                        return 'bottom-left';
                    case 'Bottom Right' :
                        return 'bottom-right';
                    case 'Top Left' :
                    default :
                        return 'top-left';
                }
            }
        },
        isOffline(){
            return (window.location.hostname === "");
        }
    }
}
</script>


<style lang="scss">
$icon-size: 38px;
$icon-position: -23px;

.Hotspot {
    font-size: 25px;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 12px 20px;

    &--offline {
        opacity: 0.5!important;
        pointer-events: none!important;
    }

    &:hover { background-color: var(--white); }

    // icon
    &::before, &::after {
        content: url(@/assets/img/ui/plus.svg);
        position: absolute;
        pointer-events: none;
        width: $icon-size;
        height: $icon-size;
    }

    // icon position
    &.top-left::before, &.top-left::after { top: $icon-position; left: $icon-position; }
    &.top-right::before, &.top-right::after { top: $icon-position; right: $icon-position; }
    &.bottom-left::before, &.bottom-left::after { bottom: $icon-position; left: $icon-position; }
    &.bottom-right::before, &.bottom-right::after { bottom: $icon-position; right: $icon-position; }

    // scene icon
    &.scene::before, &.scene::after {
        content: url(@/assets/img/ui/arrow.svg);
        top: -$icon-size;
        left: -$icon-size;
    }
    
    &::after {
        animation-name: hotspotIconLoopPulse;
        animation-iteration-count: infinite;
        animation-duration: 15s;
        animation-delay: var(--animation-delay);
    }

    

    /* entrance animation */
    &.HotspotEntrance-enter-active {
        transition: all 1s var(--tk-easeinout);
    }
    &.HotspotEntrance-leave-active {
        transition: all 0.3s var(--tk-easeinout);
    }
    &.HotspotEntrance-enter-from {
        transform: translateY(-300%);
        opacity: 0;
    }
    &.HotspotEntrance-leave-to {
        opacity: 0;
    }


}

/* default*/
@keyframes hotspotIconLoopPulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    7% {
        transform: scale(5);
        opacity: 0;
    }
    100% {
        transform: scale(5);
        opacity: 0;
    }
}
</style>