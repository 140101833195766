<template>
    <transition name="toggleContentComponent">
        <article class="hotspotMultiLinks" v-if="ready" :style="getPopupPosition">
            <button class="btnfx btnfx__hoverscale" :class="{'disabled':isOffline}" @click="openContent(link.component)" v-for="link in properties.links">{{ link.text }}</button>
        </article>
    </transition>
</template>

<script>
export default {
    props: [
        "properties",
        "activeHotspots",
        "children",
        "slug"
    ],
    data() {
        return {
            ready: false,
            positionData: {},
        }
    },
    mounted() {
        setTimeout(() => {
            this.positionData = this.activeHotspots.find(hotspot => hotspot.slug === this.slug).data.positionData
            this.ready = true;
        }, 1);
    },
    methods: {
        closeComponent() {
            this.$emit("closeComponent", this.slug)
        },
        openContent(contentID) {
            app.api.PageManager.openContentComponentFromID(contentID);
        }
    },
    computed: {
        getPopupPosition() {
            let offsetX = 0;
            let offsetY = 1084;
            let responsiveOffsets = this.$store.getters.getOffsetValue;

            return {
                left: (this.positionData.xPosition + offsetX - parseInt(responsiveOffsets.left)) + 'px',
                bottom: (offsetY - this.positionData.yPosition) + 'px'
            }
        },
        isOffline(){
            return (window.location.hostname === "");
        }

    }
   
}
</script>

<style lang="scss">
.hotspotMultiLinks {

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;

    button {
        position: relative;
        display: flex;
        cursor: pointer;

        font-size: 25px;
        text-align: left;
        color: white;
        background-color: rgb(60, 60, 60, 0.85);
        opacity: 0;
        padding: 12px 20px;

        animation-name: hotspotMultiLinksIntro;
        animation-duration: 0.6s;
        transition-timing-function: ease;
        animation-fill-mode: forwards;


        @for $i from 1 through 5 {
            &:nth-last-child(#{$i}) {
                animation-delay: ($i * 100) - 100ms;
            }
        }

        &:hover {
            background-color: rgb(0, 0, 0, 0.85);
        }

        &.disabled {
            opacity: 0.6!important;
            pointer-events: none!important;
        }
    }
}

@keyframes hotspotMultiLinksIntro {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>