<template>
  <nav id="UiLayer" :style="[getOffset]">
    <BackButton v-if="!onHomePage" />
    <ContactButton v-if="!isOffline" />
    <LanguageButton />
    <HomeButton />
  </nav>
</template>
<script>

import BackButton from '../ui/BackButton.vue';
import ContactButton from '../ui/ContactButton.vue';
import HomeButton from '../ui/HomeButton.vue';
import LanguageButton from '../ui/LanguageButton.vue';

export default {
  components: {
    BackButton,
    ContactButton,
    LanguageButton,
    HomeButton
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    onHomePage() {
      this.$route;
      return app.api.PageManager.onHomePage();
    },
    getOffset() {
      return this.$store.getters.getOffsetValue;
    },
    isOffline(){
      return (window.location.hostname === "");
    }
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  display: flex;
  z-index: 10;
  top: 50px;
  background-color: var(--dark-grey);
  margin-left: 50px;
}
</style>