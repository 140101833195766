<template>
    <router-link :to="intenalLinkRoute" @click="trackNavigation">
        {{content.text}}
    </router-link>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        trackNavigation() {
            app.api.Tracking.event("Click", "InternalLink", "Article", this.intenalLinkRoute);
        }
    },
    computed: {
        intenalLinkRoute() {
            return app.api.PageManager.getRouteFromIDs(this.content.sceneID, this.content.contentID)
        }
    }
}
</script>
<style lang="scss" scoped>
    a {
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        font-size: 16px;
        color: var(--blue);
        font-weight: bold;
        margin-block: 42px;

        &::before {
            content: url(@/assets/img/ui/link.svg);
            width: 32px;
            height: 32px;
        }
        
        .contentBlockSmall & { margin-block: 20px; }
        &:last-child { margin-bottom: 0; }

    }
</style>