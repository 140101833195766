export default function PageManager (app) {

    app.api.PageManager = Object.freeze({
        getCurrentRoute,
        navigateToRoute,
        navigateToRouteSilent,
        getRouteFromID,
        getRouteFromIDs,
        navigateToID,
        navigateBack,
        navigateToParentScene,

        getPage,
        pageExists,
        getAllPages,
        getPageFromID,

        getRootPage,
        onHomePage,
        navigateToHomePage,

        getContentComponents,
        openContentComponentFromID,
        getContentComponentExistsInManifest,
        openExplicitContentFromID,
        getContentComponentsInManifest,
        getContentComponent,
        getContentComponentFromID,
        getContentComponentInManifest,
        getComponentDataFromHotspotID,
        getComponentSlugFromHotspotID,
        getIsContentComponentOpen,
        closeAllContentComponents,
        closeContentComponent,

        getCustomData,
        getCustomComponentFromType,

        getHotspotDataFromContentID,

        getBreadcrumbs,
    })

    

    /* --------------------------------- ROUTES --------------------------------- */

    // Return current route
    function getCurrentRoute() {
        return window.app.vm.$router.currentRoute._value.path
    }

    // Navigate to a specific route using the vue router
    function navigateToRoute(route) {
        return window.app.vm.$router.push(route)
    }

    // SILENTLY Navigate to a specific route using the vue router
    function navigateToRouteSilent(route) {
        return window.app.vm.$router.replace(route)
    }

    // Return route from specific page id
    function getRouteFromID(id) {
        return getAllPages().find(page => page.data.id === id).route
    }
    
    // Compose internal route for supplied Scene ID and Content ID
    // (Compose a route for any content on any scene if you supply a sceneID and/or contentID)
    function getRouteFromIDs(sceneID, contentID) {
        var sceneRoute = ""
        var contentSlug = ""
        
        if (sceneID) {
            sceneRoute = getRouteFromID(sceneID)
        } else {
            sceneRoute = getCurrentRoute()
        }
        
        if (contentID) 
            contentSlug = ":" + getContentComponentFromID(contentID).slug


        return `${sceneRoute}${contentSlug}`
    }

    // Navigate to specific ID
    function navigateToID(id) {
        let route = getRouteFromID(id)
        return navigateToRoute(route);
    }

    // Navigate one step back using the $router
    function navigateBack() {
        window.app.vm.$router.go(-1);
    }
    
    // Navigate to parent scene
    function navigateToParentScene() {
        var newRoute = getCurrentRoute().split("/").slice(0, -1).join("/");
        if (newRoute == "") {
            return;
        }
        navigateToRoute(newRoute);
    }
    

    /* --------------------------------- PAGES --------------------------------- */

    // Get Page Object from route
    function getPage(route) {
        let _route = route[0] === '/' ? route.substr(1) : route;
        let fragments = route.replace(/:.*/, '').split('/').slice(-2)
        var result = {}

        if (fragments[0] === '') {
            result = getAllPages().find(page => page.slug === _route)
        } else {
            // this is a sub route, lets find a page with the slug and parrent
            var [parent, slug] = fragments;
            result = getAllPages().find(p => {
                return (p.parent == parent && p.slug == slug)
            })
        }

        return result ? result : getRootPage();
    }

    // Check if Page exists from route
    function pageExists(route) {
        let _route = route[0] === '/' ? route.substr(1) : route(route);
        _route = _route.split(":")[0]
        let fragments = _route.split('/').slice(-2)

        if (fragments.length === 1 && fragments[0] !== '') {
            return _route === fragments[0]
        } else {
            var [parent, slug] = fragments;
            var result = getAllPages().find(p => {
                return (p.parent == parent && p.slug == slug)
            })
            return !!result
        }
    }

    // Get all pages
    function getAllPages() {
        return app.manifest.data.pages
    }

    // Get Page data from ID
    function getPageFromID (id) {
        return getAllPages().find(page => page.id === id);
    }


    // return the Home/Root Page
    function getRootPage() {
        // HomePage is first page found without a parent
        // This means the first object in the "pages" array ie. getAllPages()[0].        
        return getAllPages().find(p => p.parent === null)
    }
    
    // Get method to return bool == true if on Home/Root Page
    function onHomePage(){
        return getAllPages().find(p => p.parent === null).route === getCurrentRoute()
    }
    
    // Function call for returning to Home Page
    function navigateToHomePage() {
        // check if a default scene is set
        if(app.manifest.data.metadata.defaultScene) {
            return navigateToRoute(getRouteFromID(app.manifest.data.metadata.defaultScene))
        } else {
            return navigateToRoute(`/${getRootPage().slug}`)
        }
    }


    /* --------------------------------- CONTENT COMPONENTS --------------------------------- */
    function getContentComponents() {
        return app.manifest.data.content
    }

    // Open content from explicit ID (Base Content)
    function openContentComponentFromID(id, singleton) {
        // Singleton is a bool, when true, closes all other content components instead of opening it on top.
        let route = getCurrentRoute();
        let contentSlug = "";
        
        // Check to see if content exists in manifest before going to manifest.data.content
        if(getContentComponentExistsInManifest(id)) {
            contentSlug = getContentComponentsInManifest().find(c => c.data.id === id).slug
        } else {
            contentSlug = getContentComponentFromID(id).slug;
        }

        // Check to see if this component should close all other content components
        if(singleton === true)
            route = route.split(':')[0]
        
        // Check to see if Content Component is already open
        if(!route.includes(contentSlug))
            navigateToRoute(route + ":" + contentSlug)
    }

    // Get Content Component data from given content ID
    function getContentComponentFromID(id) {
        return getContentComponents().find(component => component.id === id);
    }
    

    // Open content from explicit ID (Base Content)
    function openExplicitContentFromID(id, singleton) {
        // Singleton is a bool, when true, closes all other content components instead of opening it on top.
        let route = getCurrentRoute();
        let contentSlug = "";
        
        // Check to see if content exists in manifest before going to manifest.data.content
        if(getContentComponentExistsInManifest(id)) {
            contentSlug = getContentComponentsInManifest().find(c => c.data.id === id).slug
        } else {
            contentSlug = getContentComponents().find(content => content.id === id).slug
        }

        // Check to see if this component should close all other content components
        if(singleton === true)
            route = route.split(':')[0]
        
        // Check to see if Content Component is already open
        if(!route.includes(contentSlug))
            navigateToRoute(route + ":" + contentSlug)
    }
    
    // Look through getAllPages() and only return Content Components
    function getContentComponentsInManifest () {
        return getAllPages().filter(page => page.type == 'content')
    }
    
    // Get if Content Component exists in getAllPages() from given slug
    function getContentComponentExistsInManifest(id) {
        return getContentComponentsInManifest().some(component => component.data.id === id);
    }

    // Get Content Component data from given slug
    function getContentComponent(slug) {
        return getContentComponents().find(component => component.slug === slug);
    }

    // Get Content Component data from given slug specifically when referenced from manifest
    function getContentComponentInManifest(slug) {
        return getContentComponentsInManifest().find(component => component.slug === slug);
    }

    // Return if any content component is open by looking at the route.
    function getIsContentComponentOpen() {
        return getCurrentRoute().includes(":");
    }

    // Close all currently open Content Components
    function closeAllContentComponents() {
        var route = getCurrentRoute().split(':')[0];
        navigateToRoute(route)
    }
    
    // Close all currently open Content Components
    function closeContentComponent(slug) {
        var regex = new RegExp(`:${slug}\\b`, 'g');
        var route = getCurrentRoute().replace(regex, '');

        navigateToRoute(route)
    }

    /* --------------------------------- CUSTOM CONTENT --------------------------------- */
    // Get all Custom Data
    function getCustomData() {
        return app.manifest.data.custom;
    }

    // Return data for specific type of custom data
    function getCustomComponentFromType(type) {
        return getCustomData().find(c => c.type === type)
    }


    /* --------------------------------- HOTSPOTS --------------------------------- */
    // return data from a specfic hotspot's id (not its scene or compoenent reference)
    function getHotspotDataFromContentID(id) {
        return getAllPages().find(c => c.id == id);
    }

    // Return data from a Content Component ID
     function getComponentDataFromHotspotID(id) {
        return getContentComponentsInManifest().find(c => c.id == id).data
    }

    // Return slug for a Content Component ID
    function getComponentSlugFromHotspotID(id) {
        return getContentComponentsInManifest().find(c => c.id == id).slug
    }


    /* --------------------------------- BREADCRUMBS --------------------------------- */
    function getBreadcrumbs() {
        var breadcrumbs = [];
        var manifestPages = getAllPages();
        var tempRoute = getCurrentRoute().split(/[\/:]/).filter(el => el != '' || null)

        breadcrumbs = tempRoute.map(breadcrumb => {
            let page = manifestPages.find(page => page.slug === breadcrumb);
            if(page != undefined) {
                return {
                    title: page.title,
                    slug: breadcrumb,
                    route: page.route,
                    type: page.type
                };
            } else {
                return
            }
        })
        
        return breadcrumbs
    }
}