<template>
    <transition name="fadeoutTransitionVideos">
        <div id="TransitionVideos" v-if="videoQueue.length > 0">
            <video v-for="video in playingVideo" :key="video.id" :poster="video.poster" @ended="removeFromQueue" autoplay playsinline webkit-playsinline muted>
                <source :src="video.src">
            </video>
        </div>
    </transition>
</template>
<script>
export default {
    data() {
        return {
            transitions: [],
            videoQueue: []
        }
    },
    created() {
        this.getTransitions();
    },
    mounted() {
        this.checkIfTransition(null, app.api.PageManager.getCurrentRoute(), true)
    },
    watch: {
        $route(to, from) {
            let toPath = to.fullPath;
            let fromPath = from.fullPath;
            this.checkIfTransition(fromPath, toPath, false)
        }
    },
    methods: {
        getTransitions() {
            let umbracoTransitions = app.api.PageManager.getCustomComponentFromType("videoTransitions").data.transitions
            this.transitions = umbracoTransitions.map((transition, i) => {
                // create transition hook
                transition.id = `transition_${i}`;
                transition.from = transition.from ? app.api.PageManager.getRouteFromID(transition.from) : null;
                transition.to = transition.to ? app.api.PageManager.getRouteFromID(transition.to) : null;
                return transition;
            }) 
        },

        checkIfTransition(from, to, isInitialLoad) {
            for(let i = 0; i < this.transitions.length; i++) {
                if((this.transitions[i].from == from || isInitialLoad) && this.transitions[i].to == to) {
                    let video = {
                        id: this.transitions[i].id,
                        src: app.api.Utils.getMediaPath(this.transitions[i].transitionVideo.src),
                        poster: app.api.Utils.getMediaPath(this.transitions[i].transitionPoster.src)
                    }
                    this.videoQueue.push(video);
                } 
            }
        },

        removeFromQueue() {
            this.videoQueue.shift()
        },
    },
    computed: {
        playingVideo() { 
            let video = [this.videoQueue[0]];
            return video;
        }
    }
}
</script>

<style lang="scss">
#TransitionVideos {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &>video {
        width: 100%;
        height: 100%;
    }
}

.fadeoutTransitionVideos-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}

.fadeoutTransitionVideos-leave-to {
    opacity: 0;
}
</style>