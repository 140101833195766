<template>
  <transition name="toggleContentComponent">
    <article class="map-view" v-if="ready">

      <div class="map-view__topbar">
        <h2 v-html="getComponentTitle"></h2>
        <button class="map-view__topbar_close" @click="closeComponent"><img src="@/assets/img/ui/close.svg"></button>
      </div>

      <div class="map-view__wrapper">
        <ul class="map-view__nav">
          <li v-for="(view, i) in properties.views"  role="presentation" :key="i">
            <button
              :id="'button-' + i"
              :class="{'active': active === i}"
              :style="{color: view.textColor, backgroundColor: view.buttonColor }"
              type="button"
              role="tab"
              :aria-controls="'map-' + i"
              :aria-selected="active === i"
              @click="active = i"
              v-html="view.nameOfView"
            ></button>
          </li>
        </ul>

        <div class="map-view__content">
          <template v-for="(view, i) in properties.views" :key="i">
            <transition name="map-fade" mode="in-out">
              <img
              v-show="active === i"
              :id="'map-' + i"
              role="tabpanel"
              :aria-labelledby="'button-' + i"
              :src="getSrc(view.image.src)"
              :alt="view.image.name"
              >
            </transition>
          </template>
        </div>
      </div>

    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "slug",
    "componentID"
  ],
  data() {
    return {
      ready: false,
      active: 0,
    }
  },
  mounted() {
    this.ready = true
    console.log(this.properties);
  },
  methods: {
    getSrc(src) {
      return app.api.Utils.getMediaPath(src);
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    },
  },
  computed: {
    getComponentTitle() {
      let pages = app.api.PageManager.getAllPages()
      let data = pages.find(page => page.id === this.componentID)

      return data ? data.title : ""
    }
  }
}
</script>

<style lang="scss">
.map-view {
  position: absolute;
  inset: 150px 400px 150px auto;

  /* topbar with styling */
  &__topbar {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    z-index: 10;
    top: 0;
    right: -400px;
    
    h2{
      font-size: 25px;
      font-weight: 500;
      text-align: left;
      color: white;
      background-color: rgb(0, 0, 0, 0.85);
      width: 400px;
      min-height: 75px;
      padding: 22px 30px;
    }
    
    &_close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 75px;
      background-color: var(--red);
    }
  }

  /* content wrapper */
  &__wrapper {
    position: relative;
    background-color: var(--black);
    height: 100%;
    width: 1371px;
  }

  // navigation
  &__nav {
    position: absolute;
    z-index: 3;
    left: 56px;
    bottom: 140px;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    color: var(--white);

    li {
      background-color: black;
    }
    button {
      font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
      letter-spacing: -0.19px;
      padding: 5px 12px 3px;
      border-radius: 50px;
      margin: 8px 0;
      min-width: 170px;

      &[style*="rgb(0, 0, 0)"] {
        border: 1px solid var(--white);
      }
      &.active {
        outline: 2px solid var(--red);
        outline-offset: -1px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  // maps
  &__content {
    display: grid;
    position: absolute;
    inset: 0;
    height: calc(100% - 20px);
    transition: all .4s cubic-bezier(1,0,1,0); // delay fade until active is almost loaded
    margin: 10px;

    img {
      aspect-ratio: 16 / 9;
      height: 100%;
      width: 100%;
      object-fit: cover;
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  //-----  fade animation  -----//
  .map-fade-enter-active {
    transition: opacity 0.25s ease-in-out;
    z-index: 2;
  }
  .map-fade-leave-active {
    transition-delay: 0.25s;
  }
  .map-fade-enter-from {
    opacity: 0;
  }
}
</style>