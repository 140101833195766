  <template>
  <article class="Presenter" :style="[presenterPosition, scalePresenter]">
    <video id="intro" ref="intro" v-if="hasIntroVideo" v-show="introPlaying" autoplay playsinline muted>
      <source :src="getMediaPath(properties.intro.mov.src)" type='video/mp4; codecs="hvc1"'> 
      <source :src="getMediaPath(properties.intro.webm.src)" type="video/webm">
    </video>
    <video id="idle" ref="idle" v-show="!mainPlaying && !introPlaying && hasIdleVideo" :autoplay="!hasIntroVideo" playsinline muted loop>
      <source :src="getMediaPath(properties.idle.mov.src)" type='video/mp4; codecs="hvc1"'> 
      <source :src="getMediaPath(properties.idle.webm.src)" type="video/webm">
    </video>
    <video id="main" ref="main" v-show="mainPlaying && !introPlaying && hasMainVideo" playsinline>
      <source :src="getMediaPath(properties.main.mov.src)" type='video/mp4; codecs="hvc1"'> 
      <source :src="getMediaPath(properties.main.webm.src)" type="video/webm">
      <track v-if="properties.trackFile" label="english" kind="subtitles" srclang="en" :src="getMediaPath(properties.trackFile.src)" default/>
    </video>


    <button class="Presenter__button" :style="hotspotPosition" v-show="!introPlaying" @click="togglePlay">
      <span class="Presenter__button_img">
        <img v-show="!mainPlaying" src="@/assets/img/ui/presenter-play.svg">
        <img v-show="mainPlaying" src="@/assets/img/ui/presenter-pause.svg">
      </span>
      <span class="Presenter__button_text" v-if="properties.hotspotTitle" :class="{ 'hide': mainPlaying }" v-html="properties.hotspotTitle"></span>
    </button>
  </article>
  </template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "children"
  ],
  data() {
    return {
      videos: {},

      introPlaying: false,
      mainPlaying: false
    }
  },
  created() {
    this.introPlaying = this.hasIntroVideo ? true : false;
  },
  mounted() {
    this.videos = {
        intro: this.$refs.intro,
        idle: this.$refs.idle,
        main: this.$refs.main
    }

    this.events = {
        "introEnded": () => {
            this.videos.idle.currentTime = 0;
            this.videos.idle.play();
            this.introPlaying = false;
        },
        "ended": () => {
            this.mainPlaying = false;
            app.api.Tracking.event("Watched", this.properties.hotspotTitle, "Presenter")
        },

        "loadedmetadata": () => {
            this.$el.style.width = this.videos.idle.videoWidth + "px";
            this.$el.style.height = this.videos.idle.videoHeight + "px";
        }
    };

    if (this.videos.intro) {
        this.videos.intro.addEventListener("ended", this.events.introEnded)
    }
    if (this.videos.idle) {
        this.videos.idle.addEventListener("loadedmetadata", this.events.loadedmetadata)
    }
    if (this.videos.main) {
        this.videos.main.addEventListener("ended", this.events.ended)
    }

    this.preloadMainVideo();
  },
  destroy() {
        if (this.videos.intro) {
            this.videos.intro.removeEventListener("ended", this.events.introEnded)
        }
        this.videos.idle.removeEventListener("loadedmetadata", this.events.loadedmetadata)
        this.videos.main.removeEventListener("ended", this.events.ended)
        this.videos.forEach(v => v.pause())
  },
  methods: {
      togglePlay() {
        if (!this.mainPlaying) {
            this.videos.main.play();
            this.videos.idle.currentTime = 0;
            this.mainPlaying = true;
            app.api.Tracking.event( "Play", this.properties.hotspotTitle,"Presenter")
        } else {
            this.videos.main.pause();
            this.videos.main.currentTime = 0;
            this.mainPlaying = false;
            app.api.Tracking.event("Skip", this.properties.hotspotTitle, "Presenter")
        }
        console.log(this.mainPlaying);
      },

      async preloadMainVideo() { 
        if(!this.hasMainVideo)
            return


        var src = "";
        
        if(app.api.Utils.getBrowser == "Safari") {
            src = this.properties.main.mov.src        
        } else {
            src = this.properties.main.webm.src
        }

        await app.api.Utils.preloadVideo(src);
      },
      getMediaPath(src) {
        return app.api.Utils.getMediaPath(src)
      }
  },
  computed: {
      scalePresenter() {
          return {
              transform: `scale(${this.properties.presenterSize})`
          }
      },
      presenterPosition() {
          return {
              left: this.properties.presenterPosition.xPosition + "px",
              top: this.properties.presenterPosition.yPosition + "px"
          }
      },
      hotspotPosition() {
          return {
              left: this.properties.hotspotPosition.xPosition + "px",
              top: this.properties.hotspotPosition.yPosition + "px"
          }
      },
      hasIntroVideo() {
          return !(this.properties.intro.mov === null || this.properties.intro.webm === null)
      },
      hasIdleVideo() {
          return !(this.properties.idle.mov === null || this.properties.idle.webm === null)
      },
      hasMainVideo() {
          return !(this.properties.main.mov === null || this.properties.main.webm === null)
      }
  }
};
</script>

<style lang="scss">
  .Presenter {
    position: absolute;
  
    video {
      position: absolute;
      top:0;
      left: 0;
    }
  
    &__button {
      z-index: 2;
      font-size: 25px;
      display: flex;
    
      position: absolute;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.35s ease-out;

      &_img {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        background-color: var(--black);

        img {
          width: 20px;
          height: auto;
        }
      }

      &_text {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 52px;
        background-color: rgba(255, 255, 255, 0.85);
        opacity: 1;
        transition: all .5s ease;

        &.hide {
          opacity: 0;
          transform: translateX(-52px);
        }
      }
    }
  }
</style>